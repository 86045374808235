var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:(
    ("\n  <div class='w-full flex justify-between mb-2'>\n      <span class='font-EffraR text-black text-lg mr-5'\n      ><i class='far fa-clock'></i>\n      " + (_vm._moment()(_vm.event.startAt).format('HH:mm')) + "-" + (_vm._moment()(
      _vm.event.endAt
    ).format('HH:mm')) + "</span\n      >\n      <span class='font-EffraR text-shadow' style='color: " + (_vm.event.category_color ||
        '#000') + ";'>\n      <i class='fas fa-stethoscope'></i>\n      " + (_vm.event.category_title || '') + "\n      </span>\n      </div>\n\n      <div class='w-full flex justify-between items-center gap-x-6'>\n        <span class='text-left text-black font-EffraR text-lg'>\n          " + (_vm.event.patient_firstName || 'Créneau') + " " + (_vm.event.patient_lastName ||
      'réservé') + "\n        </span>\n        <span class='font-EffraR text-shadow text-black'>\n          Status : " + (_vm.$statusConsultation(_vm.event.state) || '--') + "\n        </span>\n      </div>\n  ")
  ),expression:"\n    `\n  <div class='w-full flex justify-between mb-2'>\n      <span class='font-EffraR text-black text-lg mr-5'\n      ><i class='far fa-clock'></i>\n      ${_moment()(event.startAt).format('HH:mm')}-${_moment()(\n      event.endAt\n    ).format('HH:mm')}</span\n      >\n      <span class='font-EffraR text-shadow' style='color: ${event.category_color ||\n        '#000'};'>\n      <i class='fas fa-stethoscope'></i>\n      ${event.category_title || ''}\n      </span>\n      </div>\n\n      <div class='w-full flex justify-between items-center gap-x-6'>\n        <span class='text-left text-black font-EffraR text-lg'>\n          ${event.patient_firstName || 'Créneau'} ${event.patient_lastName ||\n      'réservé'}\n        </span>\n        <span class='font-EffraR text-shadow text-black'>\n          Status : ${$statusConsultation(event.state) || '--'}\n        </span>\n      </div>\n  `\n  ",modifiers:{"top":true}}],style:(("\n  background: " + (_vm.event.category_color || '#495057') + "2D;\n  color: " + (_vm.event.category_color || '#495057') + ";\n  height: " + (_vm.event.type !== 'RESERVED-SLOT'
      ? ((_vm.itemHeight(_vm.event.category_duration)) + "px")
      : ((_vm.reservedHeight({ start: _vm.event.startAt, end: _vm.event.endAt })) + "px")) + "\n  ")),attrs:{"draggable":_vm.event.type === 'RESERVED-SLOT' ||
    _vm._moment()(_vm.event.startAt).isBefore(new Date())
      ? false
      : true},on:{"dragstart":function($event){return _vm.startDrag($event, _vm.event)},"dragover":function($event){$event.preventDefault();},"click":function($event){return _vm.clickEvent(_vm.event)}}},[_c('span',{staticClass:"font-EffraR text-base"},[_vm._v(" "+_vm._s(((_vm.event.patient_firstName || "Créneau") + " " + (_vm.event.patient_lastName || "réservé")))+" - "),_c('strong',[_vm._v(_vm._s(_vm._moment()(_vm.event.startAt).format("HH:mm"))+" / "+_vm._s(_vm._moment()(_vm.event.endAt).format("HH:mm")))])]),(_vm.event.category_duration && _vm.event.type !== 'VIDEO-CONSULTATION')?_c('span',{staticClass:"text-sm font-EffraL font-semibold"},[_vm._v(" "+_vm._s(_vm.event.category_duration)+"M ")]):_vm._e(),(_vm.event.type === 'VIDEO-CONSULTATION')?_c('span',[_c('i',{staticClass:"fas fa-video"})]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }